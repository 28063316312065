import React from "react";
import NoMatch from "./NoMatch";

export interface RouteInfo {
    path: string,
    component: any,
    routes?: RouteInfo[],
    lazy?: boolean,
    protected?: boolean
}

const ReactLazyPreload = (importStatement: any) => {
    const Component: any = React.lazy(importStatement);
    Component.preload = importStatement;
    return Component;
};

//preload pages
const SideNavigationForm = ReactLazyPreload(() => import('./pages/side-navigation/SideNavigationForm'));
SideNavigationForm.preload();

const SideNavigation = ReactLazyPreload(() => import('./pages/side-navigation/SideNavigation'));
SideNavigation.preload();

const NewsForm = ReactLazyPreload(() => import('./pages/news/NewsForm'));
NewsForm.preload();

const News = ReactLazyPreload(() => import('./pages/news/News'));
News.preload();

const BannerForm = ReactLazyPreload(() => import('./pages/banner/BannerForm'));
BannerForm.preload();

const Banner = ReactLazyPreload(() => import('./pages/banner/Banner'));
Banner.preload();

const SystemUserForm = ReactLazyPreload(() => import('./pages/system-user/SystemUserForm'));
SystemUserForm.preload();

const SystemUser = ReactLazyPreload(() => import('./pages/system-user/SystemUser'));
SystemUser.preload();

const SystemConfiguration = ReactLazyPreload(() => import('./pages/system-configuration/SystemConfiguration'));
SystemConfiguration.preload();

const ChangePassword = ReactLazyPreload(() => import('./pages/change-password/ChangePassword'));
ChangePassword.preload();

const ChangePasswords = ReactLazyPreload(() => import('./pages/settings/change-password/ChangePassword'));
ChangePasswords.preload();

const MyAccount = ReactLazyPreload(() => import('./pages/settings/my-account/MyAccount'));
MyAccount.preload();

const Deposit = ReactLazyPreload(() => import('./pages/deposits/Deposit'));
Deposit.preload();

const Role = ReactLazyPreload(() => import('./pages/role/Role'));
Role.preload();

const RoleForm = ReactLazyPreload(() => import('./pages/role/RoleForm'));
RoleForm.preload();

const Announcements = ReactLazyPreload(() => import('./pages/announcements/Announcements'));
Announcements.preload();

const AnnouncementsForm = ReactLazyPreload(() => import('./pages/announcements/AnnouncementsForm'));
AnnouncementsForm.preload();

const Accounts = ReactLazyPreload(() => import('./pages/member/Account'));
Accounts.preload();

const Groups = ReactLazyPreload(() => import('./pages/group/Group'));
Groups.preload();

const WinLossDetail = ReactLazyPreload(() => import('./pages/report/win-loss-details/WinLossDetail'));
WinLossDetail.preload();

const WinLossSummary = ReactLazyPreload(() => import('./pages/report/win-loss-summary/WinLossSummary'));
WinLossSummary.preload();

const Provider = ReactLazyPreload(() => import('./pages/game-provider/GameProvider'));
Provider.preload();

const Game = ReactLazyPreload(() => import('./pages/game/Game'));
Game.preload();

const Withdrawal = ReactLazyPreload(() => import('./pages/withdrawal/Withdrawal'));
Withdrawal.preload();

const WithdrawalForm = ReactLazyPreload(() => import('./pages/withdrawal/WithdrawalForm'));
WithdrawalForm.preload();

const Adjustment = ReactLazyPreload(() => import('./pages/adjustments/Adjustments'));
Adjustment.preload();

const AdjustmentForm = ReactLazyPreload(() => import('./pages/adjustments/AdjustmentsForm'));
AdjustmentForm.preload();

const PaymentGateway = ReactLazyPreload(() => import('./pages/payment-gateway/PaymentGateway'));
PaymentGateway.preload();

const Transaction = ReactLazyPreload(() => import('./pages/transactions/Transactions'));
Transaction.preload();

const Transfer = ReactLazyPreload(() => import('./pages/transfers/Transfers'));
Transfer.preload();

const Dashboard = ReactLazyPreload(() => import('./pages/dashboard/Dashboard'));
Dashboard.preload();

const GameCategory = ReactLazyPreload(() => import('./pages/game-category/GameCategory'));
GameCategory.preload();

const DepositBankAccount = ReactLazyPreload(() => import('./pages/deposit-bank-account/DepositBankAccount'));
DepositBankAccount.preload();

const DepositCrypto = ReactLazyPreload(() => import('./pages/deposit-crypto/DepositCrypto'));
DepositCrypto.preload();

const Messages = ReactLazyPreload(() => import('./pages/messages/Messages'));
Messages.preload();

const Promo = ReactLazyPreload(() => import('./pages/promotions/Promotions'));
Promo.preload();

const BonusSettings = ReactLazyPreload(() => import('./pages/bonus-settings/BonusSettings'));
BonusSettings.preload();

const FreeBetBonusSettings = ReactLazyPreload(() => import('./pages/bonus-freebet-settings/FreeBetBonusSettings'));
FreeBetBonusSettings.preload();

const UserBonuses = ReactLazyPreload(() => import('./pages/user-bonus/UserBonus'));
UserBonuses.preload();

const OtpRequest = ReactLazyPreload(() => import('./pages/otp/OTP'));
OtpRequest.preload();

const PromoCategory = ReactLazyPreload(() => import('./pages/promotion-category/PromotionCategory'));
PromoCategory.preload();

const RebateSettings = ReactLazyPreload(() => import('./pages/rebate-settings/RebateSettings'));
RebateSettings.preload();

const UserRebates = ReactLazyPreload(() => import('./pages/user-rebates/UserRebates'));
UserRebates.preload();

const ReferralBonusSetting = ReactLazyPreload(() => import('./pages/referral-bonus/referral-bonus-settings/ReferralBonusSettings'));
ReferralBonusSetting.preload();

const ReferralBonusReport = ReactLazyPreload(() => import('./pages/referral-bonus/referral-bonus-report/ReferralBonusReport'));
ReferralBonusReport.preload();

const Affiliate = ReactLazyPreload(() => import('./pages/affiliate/Affiliate'));
Affiliate.preload();

const ActionLogs = ReactLazyPreload(() => import('./pages/action-logs/ActionLogs'));
ActionLogs.preload();

const MemberIP = ReactLazyPreload(() => import('./pages/member-ip-tracking/MemberIPTracking'));
MemberIP.preload();

const WithdrawalMethods = ReactLazyPreload(() => import('./pages/withdrawal-methods/WithdrawalMethods'));
WithdrawalMethods.preload();

const AffiliateWithdrawal = ReactLazyPreload(() => import('./pages/affiliate-withdrawal/AffiliateWithdrawal'));
AffiliateWithdrawal.preload();

const AffiliateWithdrawalForm = ReactLazyPreload(() => import('./pages/affiliate-withdrawal/AffiliateWithdrawalForm'));
AffiliateWithdrawalForm.preload();

const AffiliateCommissionReport = ReactLazyPreload(() => import('./pages/affiliate-commission-report/AffiliateCommissionReport'));
AffiliateCommissionReport.preload();

const AffiliateSettings = ReactLazyPreload(() => import('./pages/affiliate-settings/AffiliateSettings'));
AffiliateSettings.preload();

const VipRanks = ReactLazyPreload(() => import('./pages/vip-ranks/VipRanks'));
VipRanks.preload();

const VipRankSnapshots = ReactLazyPreload(() => import('./pages/vip-rank-snapshot/VipRankSnapshot'));
VipRankSnapshots.preload();

const VpTransactions = ReactLazyPreload(() => import('./pages/vip-vp-transactions/VpTransactions'));
VpTransactions.preload();

export const routes: RouteInfo[] = [
    {
        path: "/login",
        component: React.lazy(() => import('./pages/login/Login'))
    }, {
        path: "/admin/side-navigation/add-side-navigation",
        component: SideNavigationForm,
        protected: true
    }, {
        path: "/admin/side-navigation/edit-side-navigation/:id",
        component: SideNavigationForm,
        protected: true
    }, {
        path: "/admin/side-navigation",
        component: SideNavigation,
        protected: true
    }, {
        path: "/marketing/news/add-news",
        component: NewsForm,
        protected: true
    }, {
        path: "/marketing/news/edit-news/:id",
        component: NewsForm,
        protected: true
    }, {
        path: "/marketing/news",
        component: News,
        protected: true
    }, {
        path: "/marketing/banners/add-banner",
        component: BannerForm,
        protected: true
    }, {
        path: "/marketing/banners/edit-banner/:id",
        component: BannerForm,
        protected: true
    }, {
        path: "/marketing/banners",
        component: Banner,
        protected: true
    }, {
        path: "/admin/system-users/edit-system-user/:id",
        component: SystemUserForm,
        protected: true
    }, {
        path: "/admin/system-users/add-system-user",
        component: SystemUserForm,
        protected: true
    }, {
        path: "/admin/system-users",
        component: SystemUser,
        protected: true
    }, {
        path: "/admin/configuration",
        component: SystemConfiguration,
        protected: true
    }, {
        path: "/change-password",
        component: ChangePassword,
        protected: true
    }, {
        path: "/settings/change-password",
        component: ChangePasswords,
        protected: true
    }, {
        path: "/settings/my-account",
        component: MyAccount,
        protected: true
    }, {
        path: "/transaction/deposits-approval",
        component: Deposit,
        protected: true
    }, {
        path: "/transaction/deposits",
        component: Deposit,
        protected: true
    }, {
        path: "/admin/role/edit-role/:id",
        component: RoleForm,
        protected: true
    }, {
        path: "/admin/role/add-role",
        component: RoleForm,
        protected: true
    },
    {
        path: "/admin/roles",
        component: Role,
        protected: true
    },
    {
        path: "/marketing/announcements/edit-announcement/:id",
        component: AnnouncementsForm,
        protected: true
    }, {
        path: "/marketing/announcements/add-announcement",
        component: AnnouncementsForm,
        protected: true
    }, {
        path: "/marketing/announcements",
        component: Announcements,
        protected: true
    }, {
        path: "/member/accounts",
        component: Accounts,
        protected: true
    }, {
        path: "/member/group",
        component: Groups,
        protected: true
    }, {
        path: "/report/game-report-details",
        component: WinLossDetail,
        protected: true
    }, {
        path: "/report/game-report-summary",
        component: WinLossSummary,
        protected: true
    }, {
        path: "/game-provider/provider",
        component: Provider,
        protected: true
    }, {
        path: "/game-provider/slot-game-list",
        component: Game,
        protected: true
    }, {
        path: "/game-provider/game-category-list",
        component: GameCategory,
        protected: true
    }, {
        path: "/transaction/withdrawals/withdrawals-edit/:id",
        component: WithdrawalForm,
        protected: true
    }, {
        path: "/transaction/withdrawals",
        component: Withdrawal,
        protected: true
    }, {
        path: "/transaction/withdrawals_approval",
        component: Withdrawal,
        protected: true
    }, {
        path: "/transaction/adjustments/adjustments-edit/:id",
        component: AdjustmentForm,
        protected: true
    }, {
        path: "/transaction/adjustments",
        component: Adjustment,
        protected: true
    }, {
        path: "/payment/payment-gateway",
        component: PaymentGateway,
        protected: true
    }, {
        path: "/transaction/transactions",
        component: Transaction,
        protected: true
    }, {
        path: "/transaction/transfers",
        component: Transfer,
        protected: true
    }, {
        path: "/dashboard",
        component: Dashboard,
        protected: true
    }, {
        path: "/payment/bank-accounts",
        component: DepositBankAccount,
        protected: true
    }, {
        path: "/payment/crypto",
        component: DepositCrypto,
        protected: true
    }, {
        path: "/admin/messages",
        component: Messages,
        protected: true
    }, {
        path: "/admin/messages/new",
        component: Messages,
        protected: true
    }, {
        path: "/marketing/promotion",
        component: Promo,
        protected: true
    }, {
        path: "/marketing/promotion-settings",
        component: BonusSettings,
        protected: true
    }, {
        path: "/marketing/freebet-bonus-settings",
        component: FreeBetBonusSettings,
        protected: true
    }, {
        path: "/transaction/member-bonus",
        component: UserBonuses,
        protected: true
    }, {
        path: "/member/otp-request",
        component: OtpRequest,
        protected: true
    }, {
        path: "/marketing/promotion-categories",
        component: PromoCategory,
        protected: true
    }, {
        path: "/marketing/rebate-settings",
        component: RebateSettings,
        protected: true
    }, {
        path: "/transaction/user-rebates",
        component: UserRebates,
        protected: true
    }, {
        path: "/marketing/referral-bonus-settings",
        component: ReferralBonusSetting,
        protected: true
    }, {
        path: "/report/referral-bonus-report",
        component: ReferralBonusReport,
        protected: true
    }, {
        path: "/affiliate/accounts",
        component: Affiliate,
        protected: true
    }, {
        path: "/admin/action-logs",
        component: ActionLogs,
        protected: true
    }, {
        path: "/member/ip",
        component: MemberIP,
        protected: true
    }, {
        path: "/payment/withdrawal-method",
        component: WithdrawalMethods,
        protected: true
    }, {
        path: "/transaction/affiliate-withdrawals/affiliate-withdrawals-edit/:id",
        component: AffiliateWithdrawalForm,
        protected: true
    }, {
        path: "/transaction/affiliate-withdrawals",
        component: AffiliateWithdrawal,
        protected: true
    }, {
        path: "/transaction/affiliate-withdrawals-approval",
        component: AffiliateWithdrawal,
        protected: true
    }, {
        path: "/affiliate/affiliate-commission-report",
        component: AffiliateCommissionReport,
        protected: true
    }, {
        path: "/affiliate/affiliate-settings",
        component: AffiliateSettings,
        protected: true
    }, {
        path: "/vip/vip-ranks",
        component: VipRanks,
        protected: true
    }, {
        path: "/vip/vip-rank-snapshot",
        component: VipRankSnapshots,
        protected: true
    }, {
        path: "/vip/vp-transactions",
        component: VpTransactions,
        protected: true
    }, {
        path: "*",
        component: NoMatch
    }
];