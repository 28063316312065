import { Link, useLocation } from 'react-router-dom';
import { Layout, Menu, Badge, Skeleton } from 'antd';
import smallIcon from '../../../assets/images/logo.svg';
import './SideMenu.less';
import { useEffect, useState } from 'react';
import { UPDATE_INDICATOR, useAuth } from '../../../hooks/auth/AuthContext';
import packageJson from '../../../../package.json';
import { useIntl } from 'react-intl';
import { GET_INDICATOR } from './SideMenu.gql';
import { useQuery } from '@apollo/client';
import { client } from '../../../apollo-clients';
import { forEach } from 'lodash';
import ringer from '../../../assets/sound/notification-sound.mp3';
const { Sider } = Layout;
const { SubMenu } = Menu;

interface Props {
    collapsed: boolean;
};

const SideMenu = ({ collapsed }: Props) => {
    const location = useLocation();
    const intl = useIntl();
    const [selectedKey, setSelectedKey] = useState<string>(location.pathname);
    const [subMenuKey, setSubMenuKey] = useState<string>();
    const { authState, authDispatch } = useAuth();
    const [sideNavs, setSideNavs] = useState<any[]>();
    const { data, } = useQuery(GET_INDICATOR, {
        client, fetchPolicy: 'no-cache', pollInterval: 10000 //60000
    });
    //const [indicatorList, setIndicatorList] = useState<any[]>();
    const icons = require(`@ant-design/icons`);
    const audio = new Audio(ringer);

    useEffect(() => {
        const selectedKeys = location.pathname.split('/');
        setSelectedKey(location.pathname);
        const expandedSubMenu = selectedKeys.length > 2 ? `/${selectedKeys[1]}` : undefined;
        setSubMenuKey(expandedSubMenu);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    useEffect(() => {
        let s: boolean = false;
        if (data && data.indicators.length > 0) {
            //setIndicatorList(data.indicators);
            authDispatch({ type: UPDATE_INDICATOR, payload: data.indicators });

            forEach(data.indicators, (item) => {
                if (item.indicatorCount > 0) {
                    s = true;
                }
            });

            if (s) {
                audio.play();
                let n = setInterval(() => {
                    audio.play();
                }, 10000);

                return (() => {
                    clearInterval(n)
                }); // clearInterval when the component is unmounted
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (collapsed)
            setSubMenuKey(undefined);
        else {
            const selectedKeys = location.pathname.split('/');
            const expandedSubMenu = selectedKeys.length > 2 ? `/${selectedKeys[1]}` : undefined;
            setSubMenuKey(expandedSubMenu);
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collapsed])

    useEffect(() => {
        if (authState.sideNav && authState.sideNav.length > 0) {
            const sortedData = authState.sideNav.sort((a, b) => {
                return a.order < b.order ? -1 : a.order > b.order ? 1 : 0;
            })
            const parentSideNavs: any[] = sortedData.filter(sn => !sn.parentId);

            const nestedSideNav: any[] = parentSideNavs.map(sn => {
                const parentSideNav = { ...sn };
                parentSideNav.child = sortedData.filter(c => c.parentId === sn.id);
                return parentSideNav;
            });
            setSideNavs(nestedSideNav);
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState.sideNav])

    const handleSubMenuClick = (e: any) => {
        if (!e || (e && e.length === 0)) {
            setSubMenuKey(undefined);
        } else if (e && e.length > 1) {
            setSubMenuKey(e[1]);
        } else if (e && e.length > 0) {
            setSubMenuKey(e[0]);
        }
    }

    const getIcon = (iconName: string): any => {
        const AntdIcon = icons[iconName.trim()];
        return AntdIcon ? <AntdIcon /> : null;
    }

    const renderMenu = () => {
        return (
            sideNavs ?
                <Menu theme="dark" mode="inline" triggerSubMenuAction={collapsed ? "hover" : "click"} onOpenChange={handleSubMenuClick}
                    selectedKeys={[selectedKey ? selectedKey : '/']} openKeys={subMenuKey ? [subMenuKey] : undefined}>
                    {
                        sideNavs?.map((sideNav) => {
                            var indicator = authState && authState?.indicatorList && authState.indicatorList.length > 0 ? authState?.indicatorList?.filter((u: { parentId: any; isShowIndicator: boolean; }) => u.parentId === sideNav.id && u.isShowIndicator === true) : [];
                            var isShowIndicator = indicator && indicator.length > 0 ? true : false;
                            return (
                                sideNav.child.length > 0 ?
                                    <SubMenu key={sideNav.route} icon={getIcon(sideNav.icon)}
                                        title={<>{intl.formatMessage({ id: sideNav.name })}<Badge dot={isShowIndicator} style={{ marginLeft: 5 }} ></Badge></>} >
                                        {renderChildMenu(sideNav.child)}
                                    </SubMenu >
                                    :
                                    <Menu.Item key={sideNav.route} icon={getIcon(sideNav.icon)} >
                                        <Link to={sideNav.route}>{intl.formatMessage({ id: sideNav.name })}</Link>
                                    </Menu.Item>
                            );
                        })
                    }
                </Menu >
                :
                <div style={{ margin: '30px 15px' }}>
                    <Skeleton active />
                </div>
        );
    }

    const renderChildMenu = (childMenu: any[]) => {
        return childMenu.map((child) => {
            // var indicator = authState?.indicatorList?.find((u: { id: any; }) => u.id === child.id) == null ? false
            //     : authState?.indicatorList?.find((u: { id: any; }) => u.id === child.id).isShowIndicator;

            var indicatorCount = authState?.indicatorList?.find((u: { id: any; }) => u.id === child.id) == null ? 0 : authState?.indicatorList?.find((u: { id: any; }) => u.id === child.id).indicatorCount;
            return (<Menu.Item key={child.route}>
                <Link to={child.route}>{intl.formatMessage({ id: child.name })} <Badge count={indicatorCount} style={{ marginBottom: 5, marginLeft: 2 }} /></Link>
            </Menu.Item>);
        })
    }

    return (
        <Sider className="slider-container" width={256} trigger={null} collapsible collapsed={collapsed} collapsedWidth={window.innerWidth < 768 ? 0 : 80}>
            {/* <div className="side-nav-logo">
                <img src={smallIcon} alt="small-icon" className={collapsed ? 'small-logo' : ''} />
            </div> */}
            {renderMenu()}
            <div className="version-text">
                v {packageJson.version}
            </div>
        </Sider>
    )
}

export default SideMenu;