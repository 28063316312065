import { Suspense, useEffect, useState } from 'react';
import { Switch, Route, Redirect, Router } from 'react-router-dom';
import { routes, RouteInfo } from './routes';
import { useCookies } from "react-cookie";
import { GET_CURRENT_USER_WITH_SIDE_NAV, LOGOUT } from "./shared/graphql/auth/auth.gql";
import { useLazyQuery, useMutation } from "@apollo/client";
import './App.less';
import history from './history';
// import Dashboard from './pages/dashboard/Dashboard';
import Header from './shared/components/header/GeneralHeader';
import SideMenu from './shared/components/side-menu/SideMenu';
import Footer from './shared/components/footer/GeneralFooter';
import { Layout, Spin, notification } from 'antd';
import { CLEAR_CURRENT_USER, SET_CURRENT_USER, SET_LOGIN_STATUS, useAuth } from './hooks/auth/AuthContext';
import { CLEAR_NOTIFICATION, useLayout } from './hooks/layout/LayoutContext';
import { authClient, client } from './apollo-clients';
import useSpinner from './hooks/layout/useSpinner';
import messages_en from './assets/i18n/en.json';
import message_zh_CN from './assets/i18n/zh_CN.json';
import { IntlProvider } from 'react-intl';
import Cookies from 'universal-cookie';
import NoMatch from './NoMatch';
const cookiesUni = new Cookies();
const { Content } = Layout;

const App = () => {
  const [collapsed, setCollaped] = useState(false);
  const { authState, authDispatch } = useAuth();
  const { layoutState, layoutDispatch } = useLayout();
  const { setLoading } = useSpinner();
  const [cookies] = useCookies();
  const [signOut] = useMutation(LOGOUT, {
    client: authClient,
    errorPolicy: "ignore",
    onError: (err) => signOutCleanUp()
    //swallow error and proceed to clear profile
  });
  const [getCurrentUser, { loading, data, error, called, refetch }] = useLazyQuery(GET_CURRENT_USER_WITH_SIDE_NAV, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: "all",
    // pollInterval: 60000
  });
  const aToken: any = process.env.REACT_APP_ACCESS_TOKEN || 'gm-at';
  const rToken: any = process.env.REACT_APP_REFRESH_TOKEN || 'gm-rt';

  const messages: any = {
    'en': messages_en,
    'zh-CN': message_zh_CN
  };

  //Get user profile
  useEffect(() => {
    if (authState.isLogin) {
      called ? refetch() : getCurrentUser();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.isLogin])

  const toggleSideNav = () => {
    setCollaped(!collapsed);
  };

  const logout = () => {
    signOut();
    signOutCleanUp();
  }

  const signOutCleanUp = () => {
    const isProd = process.env.NODE_ENV === "production";

    cookiesUni.remove(aToken, { path: "/", domain: isProd ? window.location.hostname : "localhost" });
    cookiesUni.remove(rToken, { path: "/", domain: isProd ? window.location.hostname : "localhost" });
    authDispatch({ type: CLEAR_CURRENT_USER });
    authDispatch({ type: SET_LOGIN_STATUS, payload: false });
    client.resetStore();
    authClient.resetStore();
    history.replace('/login');
  }

  useEffect(() => {
    if (!cookiesUni.get(rToken)) {
      signOutCleanUp();
    }// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookiesUni.get(rToken)])

  //Set loading spinner
  useEffect(() => {
    setLoading(loading);// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  //Set user profile is profile data changed
  useEffect(() => {
    if (data && !error) {
      authDispatch({ type: SET_CURRENT_USER, payload: data });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error])

  useEffect(() => {
    if (layoutState.showNotification && layoutState.showNotification.message) {
      openNotificationWithIcon();
    }// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layoutState.showNotification])

  useEffect(() => {
    const token = cookies[rToken];
    authDispatch({ type: SET_LOGIN_STATUS, payload: token ? true : false });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const protectedRoutesAccessCheck = (route: RouteInfo, location: any) => {
    if (route.protected && !authState.isLogin) {
      return (<Redirect to={{ pathname: '/login', state: { from: location } }} />);
    } else {
      return (
        <Suspense fallback={<Spin />}>
          <route.component routes={route.routes} />
        </Suspense>);
    }
  }

  const openNotificationWithIcon = () => {
    notification[layoutState?.showNotification?.type]({
      message: layoutState?.showNotification.message,
      description: layoutState?.showNotification?.description,
    });
    layoutDispatch({ type: CLEAR_NOTIFICATION })
  };

  return (
    <IntlProvider locale={layoutState.locale} messages={messages[layoutState.locale]}>
      <Spin tip="Loading..." spinning={layoutState.showLoading}>
        <Layout className="app-container">
          <Router history={history}>
            {authState.isLogin && <SideMenu collapsed={collapsed} />}
            <Layout className="site-layout">
              {authState.isLogin && <Header collapsed={collapsed} toggleSideNav={toggleSideNav} logout={logout} />}
              <Content
                id="main-layout"
                className="site-layout-background"
                style={{
                  margin: '24px 16px',
                  padding: 24,
                  minHeight: 280,
                }}>
                <Switch>
                  {/* <Route exact path='/' render={({ location }) => {
                    return authState.isLogin ? <Dashboard /> :
                      <Redirect to={{ pathname: '/login', state: { from: location } }} />
                  }} /> */}
                  {routes.map((route: RouteInfo, i) => {
                    return (
                      <Route key={i} path={route.path} render={({ location }) =>
                        protectedRoutesAccessCheck(route, location)
                      } />
                    );
                  })}
                </Switch>
              </Content>
              {authState.isLogin && <Footer />}
            </Layout>
          </Router>
        </Layout>
      </Spin>
    </IntlProvider>
  );
}

export default App;

